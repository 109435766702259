import { Injectable } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { AliceV2ChatComponent } from '../../components/alice-v2-chat/alice-v2-chat.component';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AliceUiService {
  constructor(private bottomSheet: MatBottomSheet) {}

  public forcedVisibility: Subject<boolean> = new Subject();

  public open(): MatBottomSheetRef<AliceV2ChatComponent, void> {
    return this.bottomSheet.open(AliceV2ChatComponent, { panelClass: 'fut-alice-chat', backdropClass: 'fut-alice-bg' });
  }

  public setStatus(show: boolean): void {
    this.forcedVisibility.next(show);
  }
}
