import { Component, Inject } from '@angular/core';
import { AliceV2Service } from '../../service/alice-v2.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UiService } from 'src/app/core/ui-service/ui.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { NgClass } from '@angular/common';
import { BaseDialogIconComponent } from '@futura/futura-ui/dialog';

@Component({
  selector: 'app-alice-evaluation',
  templateUrl: './alice-evaluation.component.html',
  styleUrls: ['./alice-evaluation.component.scss'],
  standalone: true,
  imports: [BaseDialogIconComponent, NgClass, MatFormField, MatLabel, MatInput, ReactiveFormsModule, FormsModule],
})
export class AliceEvaluationComponent {
  public readonly evaluationEmojis = ['cry', 'sad', 'suspicion', 'relieved_face', 'sunglasses'];
  public evaluation = -1;
  public readonly evaluationAskFeedback = 2;
  public evaluationText = '';

  constructor(
    private alice: AliceV2Service,
    private dialogRef: MatDialogRef<AliceEvaluationComponent>,
    private uiService: UiService,
    @Inject(MAT_DIALOG_DATA) private data: { thread_id: string }
  ) {}

  public onCloseDialog(): void {
    this.dialogRef.close();
    const evaluationText = this.evaluation > this.evaluationAskFeedback ? this.evaluationText.trim() : undefined;
    const evaluation = this.evaluation !== -1 ? this.evaluation : undefined;
    this.alice.chatEvaluate(this.data.thread_id, evaluation, evaluationText).subscribe({
      next: () => {
        if (evaluation)
          this.uiService.successToast({
            title: 'Feedback inviato',
            message: 'Grazie per il tuo feedback!',
          });
      },
      error: () => {
        if (evaluation)
          this.uiService.errorToast({
            title: 'Errore',
            message: "Si è verificato un errore durante l'invio del feedback",
          });
      },
    });
  }
}
