<fut-base-dialog dialogTitle="Alice">
  <div class="h-100 position-relative">
    <div class="alice-info">
      <!-- <img class="fut-emoji" src="/assets/emoji/brain.svg" alt="alice-icon" /> -->
      <div class="alice-animation-container">
        <app-alice-animations (onReady)="onChatAnimationReady($event)" [type]="'animation'"></app-alice-animations>
      </div>
      @if (messages.length <= 1) {
        <h1 class="fut-h1 m-0">{{ 'alice.chat.title' | transloco }}</h1>
        <span>{{ 'alice.chat.subtitle' | transloco }}</span>
      }
    </div>

    <div #aliceChat class="alice-chat scrollbar-hide">
      <div class="push-messages"></div>
      @for (message of messages; track message) {
        <div class="alice-message" [ngClass]="{ 'from-alice': message.role === 'assistant', 'from-user': message.role === 'user' }">
          <div class="alice-image">
            <app-alice-animations [type]="'avatar'"></app-alice-animations>
          </div>
          <div class="message" [innerHTML]="message.message"></div>
        </div>
      }
      @if (loadingMessage) {
        <div class="alice-message from-alice">
          <div class="alice-image">
            <app-alice-animations [type]="'avatar'"></app-alice-animations>
          </div>
          @if (!timeoutMessage && !inError) {
            <div class="message">
              <fut-ghost-fragment [state]="state" [textMaxChar]="15" [textMinChar]="10" class="m-0"></fut-ghost-fragment>
              <fut-ghost-fragment [state]="state" [textMaxChar]="15" [textMinChar]="7" class="m-0"></fut-ghost-fragment>
              <fut-ghost-fragment [state]="state" [textMaxChar]="15" [textMinChar]="9" class="m-0"></fut-ghost-fragment>
              <fut-ghost-fragment [state]="state" [textMaxChar]="15" [textMinChar]="12" class="m-0"></fut-ghost-fragment>
            </div>
          }
          @if (timeoutMessage || inError) {
            <div class="message">
              <div class="d-flex align-items-center mb-2" [ngClass]="{ 'fut-text-error': inError }" style="gap: 0.7em">
                <mat-icon fontSet="material-symbols-outlined" class="m-0">{{ !inError ? timeoutMessage?.icon : 'feedback' }} </mat-icon>
                @if (inError) {
                  <span>{{ 'alice.chat.message.delay.generic' | transloco }}</span>
                }
                @if (timeoutMessage && !inError) {
                  <span>{{ timeoutMessage.title | transloco }}</span>
                }
              </div>
              @if (timeoutMessage && !inError) {
                <span [innerHTML]="timeoutMessage.message | transloco"></span>
              }
              @if (inError) {
                <span [innerHTML]="'alice.chat.message.error.generic' | transloco"></span>
              }
            </div>
          }
        </div>
      }
      @if (!loadingMessage && messages.length === 1) {
        <div class="w-100">
          <div class="responses">
            @for (r of suggestedReplies; track r) {
              <div class="response">
                <span (click)="addMessage(r)">{{ r }}</span>
              </div>
            }
          </div>
        </div>
      }
    </div>
    <div class="alice-footer position-absolute">
      <div class="alice-input">
        <input (keyup)="messageOnKeyPress($event)" [(ngModel)]="userMessage" placeholder="{{ 'alice.chat.placeholder' | transloco }}" type="text" />
        <button (click)="addMessage()" [disabled]="loadingMessage" class="fut-btn fut-btn-pill fut-btn-icon fut-btn-accent">
          <mat-icon class="m-0" fontSet="material-symbols-outlined">send</mat-icon>
        </button>
      </div>
    </div>
  </div>
</fut-base-dialog>
