import { Component, ElementRef, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AliceV2Service } from '../../service/alice-v2.service';
import { MessageV2 } from 'src/app/core/interfaces/chat';
import { Observable, Subscription } from 'rxjs';
import { AliceEvaluationComponent } from '../alice-evaluation/alice-evaluation.component';
import { TranslocoService, TranslocoPipe } from '@jsverse/transloco';
import { FutDialogService } from '../../../../core/dialog-service/fut-dialog.service';
import { COMPONENT_STATE, ComponentState, GhostFragmentComponent } from '@futura/futura-ui/ghost';
import { AliceAnimations } from 'src/app/shared/alice-animations/alice-animations.utils';
import { ANALYTICS_PROVIDER } from '@futura/futura-ui';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatIcon } from '@angular/material/icon';
import { NgClass } from '@angular/common';
import { AliceAnimationsComponent } from '../../../alice-animations/alice-animations.component';
import { BaseDialogComponent } from '@futura/futura-ui/dialog';

interface TimeoutMessage {
  message: string;
  title: string;
  icon: string;
  after: number;
}

@Component({
  selector: 'app-alice-v2-chat',
  templateUrl: './alice-v2-chat.component.html',
  styleUrls: ['./alice-v2-chat.component.scss'],
  standalone: true,
  imports: [BaseDialogComponent, AliceAnimationsComponent, NgClass, GhostFragmentComponent, MatIcon, ReactiveFormsModule, FormsModule, TranslocoPipe],
})
export class AliceV2ChatComponent implements OnInit, OnDestroy {
  private readonly analyticsService = inject(ANALYTICS_PROVIDER);

  public messages: Array<MessageV2> = [];
  public suggestedReplies: Array<string> = [];
  public inError = false;
  public timeoutMessage?: TimeoutMessage;
  public loadingMessage = true;
  public state: ComponentState = COMPONENT_STATE.LOADING;
  public userMessage = '';
  private readonly allSuggestedReplies = new Array<string>(3).fill('').map((_, index) => `alice.chat.message.suggestion.${index + 1}`);
  private suggestedRepliesCount = 3;
  private timeoutMessages: Array<TimeoutMessage> = [
    {
      message: 'alice.chat.message.delay.little',
      title: 'alice.chat.message.delay.title',
      icon: 'timer',
      after: 15,
    },
    {
      message: 'alice.chat.message.delay.lot',
      title: 'alice.chat.message.delay.title',
      icon: 'timer',
      after: 25,
    },
  ];
  public aliceHandler!: AliceAnimations;
  private timeoutMessageTimeout: Array<ReturnType<typeof setTimeout>> = [];
  private lastMessage$?: Subscription;

  @ViewChild('aliceChat') private aliceChatComponent?: ElementRef;
  private loadingAnimationTimeout?: ReturnType<typeof setTimeout>;

  constructor(
    private alice: AliceV2Service,
    private dialog: FutDialogService,
    private translateService: TranslocoService
  ) {}

  public ngOnInit(): void {
    this.setupAlice();
  }

  public ngOnDestroy(): void {
    this.close();
    this.lastMessage$?.unsubscribe();
    if (this.lastMessage$) {
      this.lastMessage$.unsubscribe();
    }
    this.timeoutMessageTimeout.forEach(timeout => clearTimeout(timeout));
    this.timeoutMessageTimeout = [];
  }

  public addMessage(message?: string): void {
    if (this.loadingMessage) {
      return;
    }
    if (message) this.userMessage = message;
    this.userMessage = this.userMessage.trim();
    if (!this.userMessage) return;
    this.messages.push({
      role: 'user',
      message: this.userMessage,
      thread_id: this.messages[this.messages.length - 1].thread_id,
    });

    console.log(this.messages.length);

    this.analyticsService?.sendAnalytics({
      eventName: 'action_gpt_v2_chat_message',
      eventParams: {
        type: message ? 'suggested_answer' : 'free_message',
        conversation_length: Math.min(0, this.messages.length - 1),
      },
    });

    this.chatHandler(this.alice.chatReplay(this.messages[this.messages.length - 1].thread_id, this.userMessage));

    this.userMessage = '';
    this.onMessage();
  }

  public messageOnKeyPress(event: KeyboardEvent): void {
    if (this.loadingMessage) {
      return;
    }
    if (event.key === 'Enter') this.addMessage();
  }

  private setupAlice(): void {
    this.suggestedRepliesCount = Math.min(this.suggestedRepliesCount, this.allSuggestedReplies.length);

    const allSuggestedReplies = this.allSuggestedReplies.map(r => this.translateService.translate(r) as string);

    this.inError = false;
    delete this.lastMessage$;
    delete this.timeoutMessage;
    this.messages = [];
    this.userMessage = '';
    this.timeoutMessageTimeout = [];

    // Randomly select suggested replies
    this.suggestedReplies = allSuggestedReplies.sort(() => Math.random() - Math.random()).slice(0, this.suggestedRepliesCount);

    this.chatHandler(this.alice.chatStart());
  }

  private loadingAnimation(message: MessageV2, fullMessage: string) {
    this.loadingAnimationTimeout = setInterval(() => {
      const remaining = fullMessage.slice(message.message.length);
      if (!remaining && this.loadingAnimationTimeout) {
        clearInterval(this.loadingAnimationTimeout);
        this.aliceHandler.play({ state: 'idle', loop: true, append: false });
        this.onMessage(0);
        return;
      }
      this.onMessage(0);
      message.message += remaining.slice(0, 3);
    }, 50);
  }

  onChatAnimationReady(aliceHandler: AliceAnimations) {
    this.aliceHandler = aliceHandler;
    this.aliceHandler.play({ state: 'think', loop: true, append: false });
  }

  private chatHandler(message$: Observable<MessageV2>) {
    this.loadingMessage = true;
    if (this.aliceHandler) this.aliceHandler.play({ state: 'think', loop: true, append: false });
    this.timeoutMessages.forEach(timeoutMessage => this.setTimeoutMessage(timeoutMessage));
    this.lastMessage$ = message$.subscribe({
      next: message => {
        this.aliceHandler.talk(false);
        const newMessage = message.message.replace('--EXIT--', '').trim();

        message.message = '';
        this.messages.push(message);

        this.loadingAnimation(message, newMessage);
        this.timeoutMessageTimeout.forEach(timeout => clearTimeout(timeout));
        this.timeoutMessageTimeout = [];
        this.loadingMessage = false;

        this.inError = false;
        delete this.timeoutMessage;

        this.onMessage();
      },
      error: () => {
        this.inError = true;
        this.analyticsService?.sendAnalytics({
          eventName: 'action_gpt_v2_chat_error',
          eventParams: {
            type: this.timeoutMessage ? 'timeout' : 'error',
          },
        });
      },
    });
  }

  private setTimeoutMessage(timeoutMessage: TimeoutMessage) {
    this.timeoutMessageTimeout.push(setTimeout(() => (this.timeoutMessage = timeoutMessage), timeoutMessage.after * 1000));
  }

  private close(): void {
    if (this.messages.length < 2) {
      return;
    }

    this.dialog.open(AliceEvaluationComponent, {
      dimension: 'md',
      matConf: {
        data: { thread_id: this.messages[this.messages.length - 1].thread_id },
      },
    });
  }

  private onMessage(t = 100): void {
    // Scroll container to bottom
    setTimeout(() => {
      this.aliceChatComponent?.nativeElement.scrollTo({
        top: this.aliceChatComponent?.nativeElement.scrollHeight,
        behavior: 'smooth',
      });
    }, t);
  }
}
